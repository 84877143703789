import Resource from '../../../api/resource';
import request from '../../../utils/request';

class Questionnaires extends Resource {
    constructor() {
        super('questionnaires');
    }

    getActiveQuestionnaires() {
        return request({
            url: `/questionnaires/user`,
            method: 'get',
        })
    }

    getQuestions(id) {
        return request({
            url: `/questionnaires/get_questions/${id}`,
            method: 'get',
        })
    }

    submit(id, payload) {
        return request({
            url: `/questionnaires/submit/${id}`,
            method: 'put',
            data: payload,
        })
    }

    getQuestionnaireStatistics(id) {
        return request({
            url: `/questionnaires/stats/${id}`,
            method: 'get',
        })
    }
}   

export {Questionnaires as default};
