import Resource from '../../resource';
import request from '../../../utils/request';

class Payment extends Resource {
    constructor() {
        super('payments');
    }
    putTransaction(id, query) {
        let url = '';
        id != null ? url = `/transactions/put/${id}` : url = `/transactions/put`;
        return request({
            url: url,
            method: 'post',
            data: query,
        })
    }
    pay(id, query) {
        let url = `/payments/pay/${id}`;
        return request({
            url: url,
            method: 'post',
            data: query,
        })
    }

    get(id, installment_id) {
        return request({
            url: `/${this.uri}/${id}`,
            method: 'post',
            data: {installment_id: installment_id},
        })
    }

    printData(payload) {
        return request({
          url: payload.id ? `/payments/print-pdf/${payload.id}` : `/payments/print-pdf`,
          responseType: 'blob',
          method: 'get',
          data:payload
        })
    }
}

export { Payment as default };
