import Resource from '../../../api/resource';
import request from "../../../utils/request";

class Roles extends Resource {
    constructor() {
        super('roles');
    }

    userRoleAndPermissions(user_id) {
        return request({
            url: `/roles/user/${user_id}`,
            method: 'post',
        })
    }
    getStudentPermissions() {
        return request({
            url: '/users/permissions',
            method: 'get',
        })
    }
}

export {Roles as default};
