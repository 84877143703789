import Resource from '../../resource'
import request from '../../../utils/request'
import {getAuth} from "@/auth/utils";

class OfferingsRegistration extends Resource {
  constructor() {
    super('registrations')
  }

  getSelectedCourses(id) {
    let baseUrl = `${this.uri}/students/requested-offerings`;
    return request({
      url: baseUrl,
      method: 'get',
    })
  }

  getCurrentTermStage(id) {
    let baseUrl = `${this.uri}/students/available-termstages`;
    
    return request({
      url: baseUrl,
      method: 'get',
    })
  }

  getAvailableCourses(payload) {
    let baseUrl = `${this.uri}/students/available-offerings`;
    
    return request({
      url: baseUrl,
      method: 'get',
      params: payload,

    })
  }

  offeringSlots(payload) {
    return request({
      url: `${this.uri}/students/offering-slots/${payload.offering_id}`,
      method: 'get',
      params: payload,
    })
  }

  requestNewOffering(payload) {
    return request({
      url: `${this.uri}/students/request-offering`,
      method: 'put',
      data: payload,
    })
  }

  removeRequestedOffering(payload) {
    return request({
      url: `${this.uri}/students/remove-offering`,
      method: 'put',
      data: payload,
    })
  }

  acceptRequestedOffering(payload) {
    return request({
      url: `${this.uri}/students/accept-requested-offering`,
      method: 'put',
      data: payload,
    })
  }

  rejectRequestedOffering(payload) {
    return request({
      url: `${this.uri}/students/reject-requested-offering`,
      method: 'put',
      data: payload,
    })
  }

  withdrawRequestedOffering(payload) {
    return request({
      url: `${this.uri}/students/withdraw-offering`,
      method: 'put',
      data: payload,
    })
  }

  addDropRequestedOffering(payload) {
    return request({
      url: `${this.uri}/students/addDrop-offering`,
      method: 'put',
      data: payload,
    })
  }

  getAdvisingStudents(payload) {
    return request({
      url: `${this.uri}/advisor-students`,
      method: 'get',
      params: payload,
    })
  }

  lookup(payload) {
    return request({
      url: '/lookups/get',
      method: 'post',
      data: payload,
    })
  }

  printData(payload) {
    return request({
      url: `${this.uri}/students/export-registration/${payload.student_id}/${payload.lang}`,
      responseType: 'blob',
      method: 'get',
    })
  }

  getTermsGrades(payload){
    const url = '/students/academic/terms-grades';
    return request({
      url: url,
      method: 'get',
    })
  }

  getTakenCourses(payload){
    const url = '/students/academic/taken-courses';
    return request({
      url: url,
      method: 'get',
    })
  }

  getStudyPlan(payload) {
    let url = `/students/academic/study-plan`;
    return request({
      url: url,
      method: 'get',
    })
  }

}

export { OfferingsRegistration as default }
