import Resource from '../../resource';
import request from '../../../utils/request';

class Credit extends Resource {
    constructor() {
        super('credit-transactions');
    }

    put(query) {
        return request({
            url: `/credit-transactions/charge-my-credit`,
            method: 'post',
            data: query,
        })
    }

}

export {Credit as default};
