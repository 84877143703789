import Resource from '../../resource';
import request from '../../../utils/request';

class Registrations extends Resource {
    constructor() {
        super('registrations');
    }
    export(payload){
        return request({
          url: `/registrations/export`,
          method: 'post',
          responseType: "blob",
          data:payload,
        });
      }
}

export {Registrations as default};
