import Resource from '../../resource'
import request from '../../../utils/request'

class Offering extends Resource {
  constructor() {
    super('offerings')
  }

  add(query) {
    return request({
      url: '/offerings/add',
      method: 'post',
      data: query,
    })
  }

  put(id, query) {
    return request({
      url: `/offerings/edit/${id}`,
      method: 'put',
      data: query,
    })
  }

  // import excel file
  upload(resource, id, lang) {
    return request({
      url: `/offerings/import-student-grades/${id}/${lang}`,
      method: 'post',
      headers: { 'Content-Type': 'multipart/form-data' },
      data: resource,
    })
  }
}

export { Offering as default }