import Offering from '@/api/study/offerings/offering'

const offering = new Offering()

const fileDownload = require('js-file-download')

const offeringModule = {
    namespaced: true,
    state: {
        item: {},
        items: [],
        total: 0,
        lookups: null,
        load: false,
        courses: null,
        loadCourse: null,
    },
    getters: {
        items(state) {
            return state.items
        },
        courses(state) {
            return state.courses
        },
        item(state) {
            return state.item
        },
        load(state) {
            return state.load
        },
        total(state) {
            return state.total
        },
        status(state) {
            return state.item.removed
        },
        lookups(state) {
            return state.lookups
        },
    },
    mutations: {
        SET_ITEMS: (state, users) => {
            state.items = users
        },
        SET_TOTAL_ITEMS: (state, total) => {
            state.total = total
        },
        SET_ITEMS_LOAD: (state, load) => {
            state.load = load
        },
        SET_ITEM: (state, user) => {
            state.item = user
        },
        SET_LOOKUPS(state, lookups) {
            state.lookups = lookups
        },
    },
    actions: {
        offerings({ commit }, payload) {
            return new Promise((resolve, reject) => {
                commit('SET_ITEMS_LOAD', true)
                offering.list(payload.query).then(response => {
                    commit('SET_ITEMS', response.data)
                    commit('SET_TOTAL_ITEMS', response.meta.count)
                    commit('SET_LOOKUPS', response.meta.lookup)
                    commit('SET_ITEMS_LOAD', false)
                    resolve()
                })
            })
        },
        put({ commit }, payload) {
            commit('app/UPDATE_LOAD', true, {
                root: true,
            })
            return new Promise((resolve, reject) => {
                offering
                    .put(payload.id, payload.query)
                    .then(response => {
                        commit('app/UPDATE_LOAD', false, {
                            root: true,
                        })
                        resolve(response)
                    })
                    .catch(error => {
                        commit('app/UPDATE_LOAD', false, {
                            root: true,
                        })
                        resolve(error)
                    })
            })
        },
        add({ commit }, payload) {
            commit('app/UPDATE_LOAD', true, {
                    root: true,
                })
            return new Promise((resolve, reject) => {
                offering
                    .add(payload.query)
                    .then(response => {
                        commit('app/UPDATE_LOAD', false, {
                            root: true,
                        })
                        resolve(response)
                    })
                    .catch(error => {
                        commit('app/UPDATE_LOAD', false, {
                            root: true,
                        })
                        reject(error)
                    })
            })
        },
        get({ commit }, query) {
            return new Promise((resolve, reject) => {
                commit('SET_ITEMS_LOAD', true)
                if (typeof query === 'object') {
                    offering
                        .get(query.id, {
                            get_edit: query.get_edit,
                        })
                        .then(response => {
                            commit('SET_ITEM', response.data)
                            if ( response && response.data && typeof response.data == 'object') {
                                commit('app/UPDATE_PAGE_DETAILS', response.data, {root: true});
                              }
                            commit('SET_ITEMS_LOAD', false)
                            resolve()
                        })
                } else {
                    offering.get(query).then(response => {
                        commit('SET_ITEM', response.data)
                        commit('app/UPDATE_PAGE_DETAILS', response.data.name, {
                            root: true,
                        })
                        commit('SET_ITEMS_LOAD', false)
                        resolve(response)
                    })
                }
            })
        },
        remove({ commit }, id) {
            return new Promise((resolve, reject) => {
                offering.remove(id).then(response => {
                    resolve(response)
                })
                .catch(error => {
                    reject(error)
                })
            })
        },
        restore({ commit }, id) {
            return new Promise((resolve, reject) => {
                offering.restore(id).then(response => {
                    resolve()
                })
            })
        },
        upload({ commit }, payload) {
            return new Promise((resolve, reject) => {
                commit('app/UPDATE_LOAD', true, { root: true })
                offering.upload(payload.resource, payload.id, payload.lang)
                    .then(response => {
                        commit('app/UPDATE_LOAD', false, { root: true })
                        resolve(response)
                    })
                    .catch(error => {
                        resolve(error)
                    })
            })
        },
    },
}

export default offeringModule