import Resource from '../../../api/resource';
import request from '../../../utils/request';

class Form extends Resource {
    constructor() {
        super('questionnaires/types');
    }


}

export {Form as default};
