import Resource from '../../resource';
import request from '../../../utils/request';

class Transaction extends Resource {
    constructor() {
        super('transactions');
    }

}

export {Transaction as default};
