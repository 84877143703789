import GradesRecheck from '@/api/grades_recheck/grades_recheck';

const gradesRecheck = new GradesRecheck();

const gradesRecheckModule = {

    namespaced: true,
    state: {
        item: null,
        items: [],
        total: 0,
        lookups: null,
        load: false,
    },
    getters: {
        items(state) {
            return state.items;
        },
        item(state) {
            return state.item;
        },
        load(state) {
            return state.load;
        },
        total(state) {
            return state.total;
        },
        status(state) {
            return state.item.removed;
        },
        lookups(state) {
            return state.lookups;
        },
    },
    mutations: {
        SET_ITEMS: (state, users) => {
            state.items = users;
        },
        SET_TOTAL_ITEMS: (state, total) => {
            state.total = total;
        },
        SET_ITEMS_LOAD: (state, load) => {
            state.load = load;
        },
        SET_ITEM: (state, data) => {
            state.item = data;
        },
        SET_LOOKUPS(state, lookups) {
            state.lookups = lookups;
        },

    },
    actions: {
        gradesRecheck({ commit }, payload) {
            return new Promise((resolve, reject) => {
                commit('SET_ITEMS_LOAD', true);
                gradesRecheck.list(payload.query)
                    .then(response => {
                        commit('SET_ITEMS', response.data);
                        commit('SET_TOTAL_ITEMS', response.meta.count);
                        commit('SET_ITEMS_LOAD', false);
                        commit('SET_LOOKUPS', response.meta.lookup);
                        resolve();
                    })
            });
        },
        myPublishedOfferings({ commit },payload) {
            return new Promise((resolve, reject) => {
                commit('SET_ITEMS_LOAD', true);
                gradesRecheck.myPublishedOfferings(payload)
                    .then(response => {
                        commit('SET_ITEMS_LOAD', false);
                        resolve(response);
                    })
            });
        },

        lookups({ commit }, payload) {
            return new Promise((resolve, reject) => {
                commit('SET_ITEMS_LOAD', true);
                gradesRecheck.lookups(payload)
                    .then(response => {
                        commit('SET_LOOKUPS', response.success);
                        commit('SET_ITEMS_LOAD', false);
                        resolve();
                    })
            });
        },

        termsLookups({ commit }) {
            return new Promise((resolve, reject) => {
                commit('SET_ITEMS_LOAD', true);
                gradesRecheck.termsLookups()
                .then(response => {
                        commit('SET_LOOKUPS', response.data);
                        commit('SET_ITEMS_LOAD', false);
                        resolve();
                    })
            });
        },

        cancelGradesRecheckRequest({ commit }, payload) {
            return new Promise((resolve, reject) => {
                commit('SET_ITEMS_LOAD', true);
                gradesRecheck.cancelGradesRecheckRequest(payload.id, payload.payment_status_id).then(response => {
                    commit('SET_ITEMS_LOAD', false);
                    resolve(response);
                }).catch(error => {
                    commit('SET_ITEMS_LOAD', false);
                    reject(error);
                });
            });
        },

        submitGradesRecheck({ commit }, payload) {
            return new Promise((resolve, reject) => {
                gradesRecheck.submitGradesRecheck(payload.query).then(response => {
                    resolve(response);
                }).catch(error => {
                    reject(error);
                });
            });
        },
    },
};

export default gradesRecheckModule;